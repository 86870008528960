<template>
  <div class="switch">
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch__checkbox"
    />
    <label for="checkbox" class="switch__label cursor-hover">
      <svg :class="{checked: getUserTheme === 'dark-theme'}" class="switch__label--icon desc cursor-hover" width="13" height="26" viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1335_1370" fill="white">
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z"/>
        </mask>
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z" stroke="black" stroke-width="4" mask="url(#path-1-inside-1_1335_1370)"/>
      </svg>
      <svg :class="{checked: getUserTheme === 'dark-theme'}" class="switch__label--icon mob" width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 2C10 2 7.28473 3.15893 5.22182 5.22182C3.15893 7.28472 2 10.0826 2 13C2 15.9174 3.15893 18.7153 5.22182 20.7782C7.28472 22.8411 10 24 12.5 24V13V2Z" stroke="black" stroke-width="2"/>
      </svg>
      <!-- <svg :class="{checked: getUserTheme === 'dark-theme'}" class="switch__label--icon mob" width="14" height="26" viewBox="-2 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_3480_25715" fill="white">
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z"/>
        </mask>
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z" stroke="black" stroke-width="4" mask="url(#path-1-inside-1_3480_25715)"/>
      </svg> -->
      <!-- <svg :class="{checked: getUserTheme === 'dark-theme'}" class="switch__label--icon mob" width="20" height="26" viewBox="-2 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1335_1370" fill="white">
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z"/>
        </mask>
        <path d="M13 1.55023e-07C9.55219 1.13909e-07 6.24559 1.36964 3.80761 3.80761C1.36964 6.24558 4.56441e-07 9.55219 1.55024e-07 13C-1.46394e-07 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55218 26 13 26L13 13L13 1.55023e-07Z" stroke="black" stroke-width="4" mask="url(#path-1-inside-1_1335_1370)"/>
      </svg> -->
    </label>
    <p class="switch__text">{{ $t('header.mode') }}</p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  mounted() {
    // if (!localStorage.getItem('user-theme')) {
    //   const initUserTheme = this.getMediaPreference();
    //   this.setTheme(initUserTheme);
    // }
  },
  data() {
    return {
      userTheme: 'light-theme',
    };
  },
  computed: {
    ...mapGetters([
      'getUserTheme',
    ]),
  },
  methods: {
    ...mapMutations([
      'setUserTheme',
    ]),
    toggleTheme() {
      const activeTheme = localStorage.getItem('user-theme');
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme');
      } else {
        this.setTheme('light-theme');
      }
    },

    setTheme(theme) {
      localStorage.setItem('user-theme', theme);
      this.userTheme = theme;
      this.setUserTheme(theme);
      document.documentElement.className = theme;
    },

    // getMediaPreference() {
    //   const hasDarkPreference = window.matchMedia(
    //     '(prefers-color-scheme: dark)',
    //   ).matches;
    //   if (hasDarkPreference) {
    //     return 'dark-theme';
    //   }

    //   return 'light-theme';
    // },
  },
};
</script>

<style scoped lang="scss">
.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  position: relative;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-primary-color);
  }
  &__label {
    height: 36px;
    width: 36px;
    border: 1px solid #92A1AE;
    border-radius: 50%;

    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
    transform: translateX(-14px);

    &--icon {
      left: 5px;
      position: absolute;
      transition: all .3s ease;
      height: 36px;
      z-index: 2;

      &.mob {
        display: none;
      }

      &.checked {
        transform: rotate(-180deg);
        right: 5px;
        left: auto;

        path {
          stroke: #fff;
        }
      }
    }
  }
  &__checkbox {
    // display: none;
    opacity: 0;
  }

  &:hover {
    .switch__text::before {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .switch {
    &__text {
      transform: translateX(-8px);

      &::before {
        top: -14px;
        left: -46px;
      }
    }
    &__label {
      &--icon {
        &.checked {
          right: 4px;
        }
        &.desc {
          display: none;
        }
        &.mob {
          display: block;
        }
      }
    }
  }
}

</style>
